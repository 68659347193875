// @flow
/* global googletag */

import * as React from 'react';
import { useFela, } from 'react-fela';
import { isFixedSize, tranlateFluidSize, size2CSS, toMediaQueryString, createEmptySizesObserver, } from './utils';
import { registerViewPorts, } from './GAMresizeListener';
import AdCaption from './AdCaption';
import type { BaseAdSlotType, } from '../../flowTypes/AdSlotType';
import type { Size } from '../../flowTypes/AdSlotType';

export type StaticAdSlotProps = {
  divId: string,
  adCaption?: string,
  isPreview?: boolean,
} & BaseAdSlotType;

// On the Brightspot CMS preview banners do not render, but a placeholder
function previewStyle({ theme, sizeMapping, }) {
  const mediaQueries = previewMediaQueriesStyle(sizeMapping);

  return {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    marginInlineStart: 'auto',
    marginInlineEnd: 'auto',
    backgroundColor: theme.color('sales'),
    color: theme.color('bodyText', 'base'),
    minHeight: '30px',
    extend: [
      theme.type(0, { untilBp: 'l', }),
      theme.type(2, { fromBp: 'l', }),
      ...mediaQueries,
    ],
  };
}
/**
 *
 * @param {*} sizeMapping
 * @returns
 */
function previewMediaQueriesStyle(sizeMapping) {
  const mediaQueries = [];

  if (sizeMapping?.length > 0) {
    const sortedMapping = sizeMapping.sort((sm1, sm2) => sm1.viewport[0] - sm2.viewport[0]);

    for (let i = 0; i < sortedMapping.length; i += 1) {
      const width1 = sortedMapping[i].viewport[0];
      const width2 = sortedMapping[i + 1]?.viewport[0];

      const mqKey = toMediaQueryString([ width1, width2, ]);
      const size = sortedMapping[i].sizes.length > 0 ? sortedMapping[i].sizes[0] : [ 0, 0, ];
      const mq = {
        [`@media ${mqKey}`]: {
          width: `${size[0]}px`,
          height: `${size[1]}px`,
        },
      };

      mediaQueries.push(mq);
    }
  }

  return mediaQueries;
}

export default function StaticAdSlot({ contentId, adUnitPath, sizes, sizeMapping = null, targeting = null, cssClass, divId, adCaption = null, isPreview = false, slotOnLoad, slotImpressionViewable, }: StaticAdSlotProps) {
  const [ isShowCaption, setIsShowCaption, ] = React.useState(false);
  const slotRef = React.useRef(null);

  const { css, } = useFela({ sizeMapping, });

  // Calculate inine style object
  const style = React.useMemo(() => {
    let cssObj = null;

    if (isFixedSize(sizes)) {
      cssObj = size2CSS(sizes);
    }

    return cssObj;
  }, [ sizes, ]);

  // Event handler for slot-onLoad event
  const slotOnLoadEventHandler = React.useMemo(() => {
    if (!slotOnLoad && !adCaption) {
      return null;
    }

    return event => {
      if (slotRef.current === event.slot) {
        // Show ad-slot caption when ad-slot is loaded
        if (adCaption) {
          setIsShowCaption(!event.isEmpty);
        }

        if (slotOnLoad) {
          slotOnLoad(slotRef.current);
        }
      }
    };
  }, [ adCaption, slotOnLoad, ]);

  // Event handler for slot-impressionViewable event
  const impressionViewableEventHandler = React.useMemo(() => {
    if (!slotImpressionViewable) {
      return null;
    }

    return event => {
      if (slotRef.current === event.slot) {
        slotImpressionViewable(slotRef.current);
      }
    };
  }, [ slotImpressionViewable, ]);

  React.useEffect(() => {
    if (!sizeMapping?.length || !adCaption) {
      return undefined;
    }

    const observer = createEmptySizesObserver(sizeMapping);

    if (observer) {
      const onChange = event => {
        if (event.matches) {
          setIsShowCaption(false);
        }
        else {
          setIsShowCaption(true);
        }
      };

      observer.addEventListener('change', onChange);

      return () => {
        observer.removeEventListener('change', onChange);
      };
    }

    return undefined;
  }, [ sizeMapping, adCaption, ]);

  // initialize ad-slot
  React.useEffect(() => {
    if (!adUnitPath || !sizes) {
      return undefined;
    }

    const adSizes = isFixedSize(sizes) ? tranlateFluidSize(sizes) : sizes.map(tranlateFluidSize);
    let adSizeMappingBuilder = null;
    let slot = null;

    if (sizeMapping?.length) {
      // Create listeners for screen-width resize
      registerViewPorts(sizeMapping.map(sm => sm.viewport));
    }

    // Google AD init
    !isPreview && googletag.cmd.push(() => {
      slot = googletag.defineSlot(adUnitPath, adSizes, divId)?.addService(googletag.pubads());
      slotRef.current = slot;

      if (!slot) {
        return;
      }

      if (sizeMapping && sizeMapping.length > 0) {
        adSizeMappingBuilder = googletag.sizeMapping();

        sizeMapping.forEach(mapping => {
          adSizeMappingBuilder.addSize(mapping.viewport, mapping.sizes.map(tranlateFluidSize));
        });

        slot.defineSizeMapping(adSizeMappingBuilder.build());
      }

      if (targeting) {
        slot.updateTargetingFromMap(targeting);
      }

      if (slotOnLoadEventHandler) {
        googletag.pubads().addEventListener('slotOnload', slotOnLoadEventHandler);
      }

      if (impressionViewableEventHandler) {
        googletag.pubads().addEventListener('impressionViewable', impressionViewableEventHandler);
      }

      googletag.display(slot);
    });

    // Destroy slot on un-mount
    return () => {
      if (slot) {
        if (slotOnLoadEventHandler) {
          googletag.pubads().removeEventListener('slotOnload', slotOnLoadEventHandler);
        }

        if (impressionViewableEventHandler) {
          googletag.pubads().removeEventListener('impressionViewable', impressionViewableEventHandler);
        }

        googletag.destroySlots([ slot, ]);
      }
    };
  }, [ adUnitPath, sizes, sizeMapping, targeting, divId, adCaption, isPreview, slotOnLoadEventHandler, impressionViewableEventHandler, ]);

  return (
    <>
      {adCaption && isShowCaption
        ? <AdCaption caption={adCaption} isVisible={isShowCaption} />
        : null}
      <div id={divId} style={style} className={isPreview && css(previewStyle)} suppressHydrationWarning data-cid={contentId} data-adunit={adUnitPath} />
    </>
  );
}
