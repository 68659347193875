// @flow
import React from 'react';
import { useFela, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';
import type { StyleProps, } from '@haaretz/htz-css-tools';

type PropsType = {
  caption: string,
  isVisible?: boolean,
  miscStyles?: StyleProps,
};

function captionStyle({ theme, isVisible, miscStyles, }: PropsType) {
  return {
    display: isVisible ? 'block' : 'none',
    textAlign: 'center',
    extend: [
      theme.type(-3),
      ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
    ],
  };
}

export default function AdCaption({ caption, isVisible = false, miscStyles = null, }: PropsType) {
  const { css, } = useFela({ isVisible, miscStyles, });

  return (
    <div className={css(captionStyle)}>
      {`- ${caption} -`}
    </div>
  );
}
