// @flow
/* global window googletag */

import { toMediaQueryString, } from './utils';

const BREAKPOINTS = new Set();
const BREAKPOINTS_SORTED = [];
const mqs = [];

/**
 * Adds a new breakpoint to available AdManager breakpoints.
 * This function should be called by Ad-Slot components that support responsive-ads
 *
 * @param {number} mqMinWidth minimum width of a breakpoint
 * @returns true if a new breakpoint was added
 */
function addViewportMinWidth(mqMinWidth: number) {
  let added = false;

  if (mqMinWidth < 0) {
    throw new Error('Negative values are invalid for viewport');
  }

  if (!BREAKPOINTS.has(mqMinWidth)) {
    BREAKPOINTS.add(mqMinWidth);
    let bpPos = BREAKPOINTS_SORTED.findIndex(bp => bp > mqMinWidth);

    if (bpPos === -1) {
      bpPos = BREAKPOINTS_SORTED.length;
    }

    BREAKPOINTS_SORTED.splice(bpPos, 0, mqMinWidth);

    // Adding a new media-query-list between two others, requires to re-create them
    const start = Math.max(0, bpPos - 1); // prevent using index -1
    const end = Math.min(BREAKPOINTS.size - 1, bpPos + 1); // prevent using index out of bound
    for (let i = start; i <= end; i += 1) {
      const bp = (typeof BREAKPOINTS_SORTED[i] === 'number')
        ? BREAKPOINTS_SORTED[i]
        : mqMinWidth;

      if (mqs[i]) {
        mqs[i].removeEventListener('change', mediaChangeEventHandler);
        delete mqs[i];
      }

      mqs[i] = createMq(bp, i, BREAKPOINTS_SORTED);
      mqs[i].addEventListener('change', mediaChangeEventHandler);
    }

    added = true;
  }

  return added;
}

/**
 * Adds a new breakpoints to available AdManager breakpoints.
 * This function should be called by Ad-Slot components that support responsive-ads
 *
 * @param {Array<[ number, number ]>} viewports width X height of a breakpoint
 */
export function registerViewPorts(viewports: Array<[ number, number]>) {
  for (const vp of viewports) {
    addViewportMinWidth(vp[0]);
  }
}

function createMq(bp: number, idx: number, bps: Array<number>) {
  const nextBp = bps[idx + 1];
  const mq = window.matchMedia(
    toMediaQueryString([ bp, nextBp, ])
  );

  return mq;
}

function mediaChangeEventHandler(event: MediaQueryListEvent) {
  if (event.matches) {
    const gtag = window.googletag || { cmd: [], };

    gtag.cmd.push(() => {
      googletag.pubads().refresh();
    });
  }
}
