// @flow
/* global googletag localStorage window */

import * as React from 'react';

import { inlineStyle2Obj, tranlateFluidSize, } from './utils';

import type { BaseAdSlotType, } from '../../flowTypes/AdSlotType';

type OutOfPageAdSlotProps = {
  divId: String,
  isPreview?: boolean,
} & BaseAdSlotType;

export const OutOfPageFormat = Object.freeze({
  BOTTOM_ANCHOR: 3, // Anchor format where slot sticks to the bottom of the viewport.
  GAME_MANUAL_INTERSTITIAL: 7,
  INTERSTITIAL: 5, // Web interstitial creative format.
  LEFT_SIDE_RAIL: 8,
  REWARDED: 4, // Rewarded format.
  RIGHT_SIDE_RAIL: 9,
  TOP_ANCHOR: 2, // Anchor format where slot sticks to the top of the viewport.
});

const HALF_MINUTE = 30000; // millis

/**
 * Writes to the local-storage the time when slot was rendered to page (googletag.display() was called)
 * and when it was actually viewed on page ('slotOnload' event was triggered).
 *
 * The 'GAM_OutOfPage' key is used for a anoncing **other banners on the page** that an OutOfPage slot was rendered.
 *
 * @returns the time in millis
 */
function setOutOfPageSlotRenderTime(loaded = true) {
  let now = Date.now();

  if (!loaded) {
    now -= HALF_MINUTE;
  }
  localStorage && localStorage.setItem('GAM_OutOfPage', now.toString());

  return now;
}

/**
 * Writes to the local-storage the time when slot was actualy viewed on page ('slotOnload' event was triggered).
 *
 * The 'GAM_<contentId>' key is used by the out-of-page banner to test when was the last time it actually viewed
 *
 * @param contentId - id of the ad-slot
 * @returns the time in millis
 */
function setOutOfPageSlotViewTime(contentId: string, loaded = true) {
  const adSlotKey = `GAM_${contentId}`;
  const time = setOutOfPageSlotRenderTime(loaded);
  localStorage && localStorage.setItem(adSlotKey, time);

  return time;
}

export default function OutOfPageAdSlot({ contentId, adUnitPath, sizeMapping = null, divId, targeting = null, cssClass, inlineStyle, isPreview = false, slotOnLoad, slotImpressionViewable, }: OutOfPageAdSlotProps) {
  // The managed-ads formats automatically creates and inserts its own container into the page.
  const isManagedOutOfPageAd = React.useMemo(() => Object.values(OutOfPageFormat).includes(divId), [ divId, ]);
  const [ isSupported, setIsSupported, ] = React.useState(false);
  const slotRef = React.useRef(null);
  const OutOfPageAdSlotLoaded = React.useRef(false);

  const style = React.useMemo(() => (inlineStyle ? inlineStyle2Obj(inlineStyle) : null), [ inlineStyle, ]);

  const slotOnLoadEventHandler = React.useMemo(() => {
    if (!slotOnLoad) {
      return null;
    }

    return event => {
      if (slotRef.current === event.slot) {
        OutOfPageAdSlotLoaded.current = true;
        slotOnLoad(slotRef.current);
      }
    };
  }, [ slotOnLoad, ]);

  const impressionViewableEventHandler = React.useCallback(event => {
    if (slotRef.current === event.slot) {
      slotImpressionViewable && slotImpressionViewable(slotRef.current);
      setOutOfPageSlotViewTime(contentId, true);
      setOutOfPageSlotRenderTime(true);
    }
  }, [ contentId, slotImpressionViewable, ]);

  const slotRenderEndedEventHandler = React.useCallback(event => {
    if (OutOfPageAdSlotLoaded.current === false && slotRef.current === event.slot) {
      OutOfPageAdSlotLoaded.current = true;
      setOutOfPageSlotViewTime(contentId, false);
      setOutOfPageSlotRenderTime(false);
    }
  }, [ contentId, ]);


  React.useEffect(() => {
    let adSizeMappingBuilder = null;
    let slot = null;

    // Google AD init
    !isPreview && googletag.cmd.push(() => {
      slot = googletag
        .defineOutOfPageSlot(adUnitPath, divId);
      slotRef.current = slot;

      // Slot returns null if the page or device does not support interstitials.
      if (!slot) {
        return;
      }

      if (sizeMapping && sizeMapping.length > 0) {
        adSizeMappingBuilder = googletag.sizeMapping();

        sizeMapping.forEach(mapping => {
          adSizeMappingBuilder.addSize(mapping.viewport, mapping.sizes.map(tranlateFluidSize));
        });

        slot.defineSizeMapping(adSizeMappingBuilder.build());
      }

      // Slot is not null and can be displayed.
      setIsSupported(true);

      slot.addService(googletag.pubads());

      if (targeting) {
        slot.updateTargetingFromMap(targeting);
      }

      if (slotOnLoadEventHandler) {
        googletag.pubads().addEventListener('slotOnload', slotOnLoadEventHandler);
      }

      if (impressionViewableEventHandler) {
        googletag.pubads().addEventListener('impressionViewable', impressionViewableEventHandler);
      }

      if (slotRenderEndedEventHandler) {
        googletag.pubads().addEventListener('slotRenderEnded', slotRenderEndedEventHandler);
      }

      if (window.location.search.includes('debug')) {
        [
          'impressionViewable',
          'rewardedSlotClosed',
          'rewardedSlotGranted',
          'rewardedSlotReady',
          'slotOnload',
          'slotRenderEnded',
          'slotRequested',
          'slotResponseReceived',
          'slotVisibilityChanged',
        ].forEach(eventName => {
          googletag.pubads().addEventListener(eventName, event => { event.slot.getAdUnitPath().includes('new_int') && console.log(`!!!!!!${eventName}`, event.slot.getAdUnitPath()); });
        });
      }
      googletag.display(slot);

      // Some banners should not be displayed if an OutOfPage banner (such as interstitial) is displayed.
      // This is how we let them know
      setOutOfPageSlotRenderTime();
    });

    // Destroy slot on un-mount
    return () => {
      if (slot) {
        if (slotOnLoadEventHandler) {
          googletag.pubads().removeEventListener('slotOnload', slotOnLoadEventHandler);
        }

        if (impressionViewableEventHandler) {
          googletag.pubads().removeEventListener('impressionViewable', impressionViewableEventHandler);
        }

        googletag.destroySlots([ slot, ]);
      }
    };
  }, [ adUnitPath, contentId, divId, impressionViewableEventHandler, isPreview, sizeMapping, slotOnLoadEventHandler, slotRenderEndedEventHandler, targeting, ]);

  return isSupported && !isManagedOutOfPageAd
    ? <div id={divId} style={style} data-adunit={adUnitPath} className={cssClass} suppressHydrationWarning />
    : null;
}
